import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import { gsap } from 'gsap';

export default {
	init() {
		console.log('how we work');

		const footer = document.querySelector('footer');
		//footer.classList.add('!hidden');

		jQuery(document).ready(function ($) {
			var $items = $('.js-toggle-method'),
				indexMethod = 0,
				isNormal = true,
				$footer = $('#fullpage_footer'),
				$fixedFooter = $footer.clone();

			//manageFooter();

			const $titleHero = $('#primary h1');
			const $textHero = $('#primary p');
			const $metBg = $('.at_met_img_bkg');

			$items.on('click', function (e) {
				e.preventDefault();
				var $this = $(this);
				if (!$this.hasClass('active')) {
					manageOpen($this);
				}
			});

			function manageOpen(item) {
				$items.removeClass('active');
				$items.addClass('closed');
				item.toggleClass('active closed');

				indexMethod = item.index();
				isNormal = false;
			}

			$('.js-close-method').bind('click', function (e) {
				e.stopPropagation();
				$items.removeClass('active');
				$items.removeClass('closed');
				isNormal = true;
			});

			// FULLPAGE
			var fullPageInstance = new fullpage('#fullpage', {
				licenseKey: 'HLN78-HX9J9-J1W76-I0KZ8-SANJM',
				credits: { enabled: false },
				scrollOverflow: false,
				scrollHorizontallyKey:
					'bmxjM1JuTFdGMFptRmpkRzl5ZVM1amIyMD14bl9VNThjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVVNTA=', //see https://goo.gl/xkUmHS,
				responsiveWidth: 768,
				normalScrollElements: '#masthead',
				lazyLoading: true,
				onLeave: function (origin, destination, direction, trigger) {
					var leavingSection = this;
					if(trigger == 'wheel'){
						// da hero a metodo
						console.log(destination.index, trigger)
						if (origin.index == 0 && direction == 'down') {
							hideTitle();
							showMethods();
						}
						// da metodo a hero
						if(origin.index == 1 && direction == 'up'){
							hideMethods();
							showTitle();
						}
						// da metodo a footer
						if (origin.index == 1 && direction == 'down') {
							//$fixedFooter.addClass('visible');
						}
					}
				},
				beforeLeave: function (origin, destination, direction, trigger) {
					if(trigger == 'wheel'){
						// da metodo a footer --> mostro l'immagine di sfondo del metodo prima di arrivare al footer
						if (origin.index == 1 && direction == 'down') {
							//$fixedFooter.addClass('visible')
							$metBg.removeClass('md:hidden');
						}
						// da metood a hero --> nascondo l'immagine di sfondo del metodo
						if(origin.index == 1 && direction == 'up'){	
							//$fixedFooter.removeClass('visible');
							$metBg.addClass('md:hidden');
						}
					}
				},
				afterLoad: function(origin, destination, direction, trigger){
					var origin = this;
					if(trigger == 'wheel'){
						// da hero a metodo --> mostro l'immagine di sfondo del metodo solo quando finisce l'animazione
						if(origin.index == 0 && direction == 'down'){
							$metBg.removeClass('md:hidden');
						}
					}
					
				},
				afterRender: function(){
					var pluginContainer = this;
					//footer.classList.remove('!hidden');
					//alert("The resulting DOM structure is ready");
				}
			});

			// GSAP
			function showTitle() {
				gsap.to($titleHero, {
					duration: 0.5,
					delay: 0.5,
					y: 0,
					opacity: 1,
				});
				gsap.to($textHero, {
					duration: 0.5,
					delay: 0.5,
					y: 0,
					opacity: 1,
				});
			}

			function hideTitle() {
				gsap.to($titleHero, {
					duration: 0.5,
					opacity: 0,
				});
				gsap.to($textHero, {
					duration: 0.5,
					opacity: 0,
				});
			}

			function showMethods(){
				gsap.to('#methodsContent .method_item:nth-child(1)', {
					duration: 0.5,
					y: 0,
					opacity: 1
				});
				gsap.to('#methodsContent .method_item:nth-child(2)', {
					duration: 0.5,
					delay: 0.2,
					y: 0,
					opacity: 1
				});
				gsap.to('#methodsContent .method_item:nth-child(3)', {
					duration: 0.5,
					delay: 0.4,
					y: 0,
					opacity: 1
				});
			}

			function hideMethods(){
				gsap.to('#methodsContent .method_item:nth-child(1)', {
					duration: 0.5,
					opacity: 0
					
				});
				gsap.to('#methodsContent .method_item:nth-child(2)', {
					duration: 0.5,
					delay: 0.2,
					opacity: 0
				});
				gsap.to('#methodsContent .method_item:nth-child(3)', {
					duration: 0.5,
					delay: 0.4,
					opacity: 0
					
				});
			}

			function manageFooter() {
				$footer.addClass('real-footer');
				$fixedFooter.addClass('clone-footer');
				$('#fullpage').after($fixedFooter);
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
