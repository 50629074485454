require('fullpage.js/dist/fullpage.css');
require('../util/fullpage.scrollHorizontally.min.js');
// require('../util/fullpage.parallax.min.js')
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

export default {
	init() {
		console.log('careers');	
		
		const btns = document.querySelectorAll('.at_careers_btn'); //bottoni per aprire il l'annuncio

		const filterContainer = document.querySelector('#at_careers_filter_container'); //container dei filtri

		const atFilterBtn = document.querySelectorAll('.at_filter_btn'); //filtri

		const careersContainer = document.querySelectorAll('.at_career_container'); //contenitori dei lavori


		jQuery(document).ready(function ($) {
			var $logos = $('.units_logos'),
				$items = $('.js-toggle-unit'),
				indexUnit = 0,
				logosShown = false;

			

			$items.bind('click', function (e) {
				e.preventDefault();
				if (!$(this).hasClass('active')) {
					$items.removeClass('active flex-1');
					$(this).addClass('active flex-1');
					indexUnit = $(this).index();
				}
			});

		});
		
		// ciclo per chiudere tutti i contenuti
		function closeCareers(){
			btns.forEach((btn) => {
				const annuncioContainer = btn.parentElement.parentElement;
				const annuncioContent = annuncioContainer.querySelector('.at_content_careers');
				hiddenCareers(annuncioContainer, annuncioContent);
			});
		}

		// nascondo il contenuto
		function hiddenCareers(annuncioContainer, annuncioContent){
			annuncioContainer.classList.remove('open_careers');
			annuncioContainer.classList.add('close_careers');
			annuncioContent.classList.add('hidden_careers')
		}

		// mostro il contenuto
		function showCareers(annuncioContainer, annuncioContent){
			annuncioContainer.classList.remove('close_careers');
			annuncioContainer.classList.add('open_careers');
			annuncioContent.classList.remove('hidden_careers')
		}

		// ciclo per gestire il click
		btns.forEach((btn) => {
			btn.addEventListener('click', () => {

				const annuncioContainer = btn.parentElement.parentElement; //prendo il contenitore dell'annuncio

				const annuncioContent = annuncioContainer.querySelector('.at_content_careers'); //prendo il contenuto dell'annuncio
				
				if(annuncioContainer.classList.contains('open_careers')) {
					hiddenCareers(annuncioContainer, annuncioContent);
					
				}
				else {
					closeCareers();
					showCareers(annuncioContainer, annuncioContent);
					
				}
				

				
			});
		});

		// ciclo per gestire i filtri
		atFilterBtn.forEach((btn) => {
			btn.addEventListener('click', () => {	
				filterContainer.classList.remove('open_filter_careers');
				
				const dataFilter = btn.getAttribute('data-filter');
				
				if(dataFilter == 'all') {
					careersContainer.forEach((item) => {
						item.classList.remove('hidden');
					});
					atFilterBtn.forEach((otherBtn) => {
						otherBtn.classList.remove('active_filter_btn');
					});
					btn.classList.add('active_filter_btn');
				}
				else {
					// Se il pulsante cliccato è già attivo, rimuovi il filtro e mostra tutti gli elementi
					if (btn.classList.contains('active_filter_btn')) {
						careersContainer.forEach((item) => {
							item.classList.remove('hidden');
						});
						btn.classList.remove('active_filter_btn');
						atFilterBtn[0].classList.add('active_filter_btn');
					} 
					else {
						const filteredItems = Array.from(document.querySelectorAll(`[data-filter="${dataFilter}"]`));
			
						careersContainer.forEach((item) => {
							if (filteredItems.includes(item)) {
								item.classList.remove('hidden');
							} else {
								item.classList.add('hidden');
							}
						});
			
						// Aggiungi la classe attiva solo al pulsante cliccato
						atFilterBtn.forEach((otherBtn) => {
							if (otherBtn !== btn) {
								otherBtn.classList.remove('active_filter_btn');
							}
						});
						btn.classList.add('active_filter_btn');
					}
				}
			});
		});
		
		
		

		// jQuery(document).ready(function ($) {
		// 	var $logos = $('.units_logos'),
		// 		$items = $('.js-toggle-unit'),
		// 		$footer = $('#fullpage_footer'),
		// 		$fixedFooter = $footer.clone(),
		// 		indexUnit = 0,
		// 		logosShown = false;

		// 	manageFooter();

		// 	$items.bind('click', function (e) {
		// 		e.preventDefault();
		// 		if (!$(this).hasClass('active')) {
		// 			$items.removeClass('active flex-1');
		// 			$(this).addClass('active flex-1');
		// 			indexUnit = $(this).index();
		// 		}
		// 	});

		// 	new fullpage('#fullpage', {
		// 		licenseKey: 'HLN78-HX9J9-J1W76-I0KZ8-SANJM',
		// 		credits: { enabled: false },
		// 		scrollOverflow: false,
		// 		verticalCentered: false,
		// 		responsiveWidth: 768,
		// 		normalScrollElements: '#masthead',
		// 		beforeLeave: function (
		// 			origin,
		// 			destination,
		// 			direction,
		// 			trigger
		// 		) {
		// 			if (
		// 				origin.index == 0 &&
		// 				!logosShown &&
		// 				direction == 'down' &&
		// 				trigger == 'wheel'
		// 			) {
		// 				$logos.removeClass('md:translate-y-full');
		// 				logosShown = true;
		// 				return false;
		// 			}

		// 			if (origin.index == 1) {
		// 				direction == 'down'
		// 					? $fixedFooter.addClass('visible')
		// 					: $fixedFooter.removeClass('visible');
		// 			}

		// 			/* if (origin.index == 1 && trigger == 'wheel') {
		// 				if (direction == 'down') {
		// 					if (indexUnit < $items.length - 1) {
		// 						$items.removeClass('active flex-1');
		// 						$($items[indexUnit + 1]).addClass(
		// 							'active flex-1'
		// 						);
		// 						indexUnit++;
		// 						return false;
		// 					}
		// 				} else {
		// 					if (indexUnit > 0) {
		// 						$items.removeClass('active flex-1');
		// 						$($items[indexUnit - 1]).addClass(
		// 							'active flex-1'
		// 						);
		// 						indexUnit--;
		// 						return false;
		// 					}
		// 				}
		// 			} */
		// 		},
		// 		afterLoad: function (origin, destination, direction, trigger) {
		// 			if (destination.index == 0 && logosShown) {
		// 				$logos.addClass('md:translate-y-full');
		// 				logosShown = false;
		// 				return false;
		// 			}
		// 		},
		// 	});

		// 	function manageFooter() {
		// 		$footer.addClass('real-footer');
		// 		$fixedFooter.addClass('clone-footer');
		// 		$('#fullpage').after($fixedFooter);
		// 	}
		// });
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
